import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './style.module.css';

let ContactUs = () => {
    let {contact_section} = styles;
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Call NOW!
        </Tooltip>
      );
    return(
        <>
            <span id="contacts"></span>
            <Row className="py-5 justify-content-center align-items-center" id={contact_section}>
                <Col>
                    <Container>
                        <Row className="justify-content-center align-items-center">
                            <Col md="8">
                                <h2 className="text-center mb-3">Contact Us!</h2>
                                <p>Our team is looking forward to hearing form you and answering and questions you have about Jordan Tree Service.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="justify-content-center align-items-center">
                                    <Col md="8">
                                        <h3 className="mb-4">Contact Information</h3>
                                        <Row>
                                            <Col className="col-md-3 col-12">
                                                <ul>
                                                    <li><strong>Jose Jordan</strong></li>
                                                    <li>CEO</li>
                                                    <li>
                                                        <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip}
                                                                >
                                                            <a href="tel:916-926-3326" data-bs-toggle="tooltip" data-bs-title="Call now">916-926-3326</a>
                                                        </OverlayTrigger>
                                                    </li>
                                                    <li className="pt-3">Office</li>
                                                    <li>
                                                        <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip}
                                                                >
                                                            <a href="tel:1-209-922-3241">401-895-6163</a>
                                                        </OverlayTrigger>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col className="col-md-3 col-12">
                                                <ul>
                                                    <li><strong>Information</strong></li>
                                                    <li>
                                                        <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                
                                                                >
                                                            <a href="mailto:yenifer.linares@jordantreeservices.com">yenifer.linares@jordantreeservices.com</a>
                                                        </OverlayTrigger>
                                                    </li>
                                                </ul>
                                            </Col>
                                            {/* <Col className="col-md-3 col-12">
                                                <ul>
                                                    <li><strong>Orbyn Bracho</strong></li>
                                                    <li>Field supervisor</li>
                                                    <li>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip}
                                                            >
                                                            <a href="tel:(401) 516-6594">(401) 516-6594</a>
                                                        </OverlayTrigger>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col className="col-md-3 col-12">
                                                <ul>
                                                    <li><strong>Margaret Charies</strong></li>
                                                    <li>Sales</li>
                                                    <li>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip}
                                                            >
                                                            <a href="tel:1-209-922-2300">1-209-922-2300</a>
                                                        </OverlayTrigger>
                                                    </li>
                                                </ul>
                                            </Col> */}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            
        </>
    )
}

export default ContactUs;